define('gooru-web/controllers/player-resource',['exports','ember','gooru-web/utils/utils','gooru-web/config/environment','gooru-web/config/config','gooru-web/mixins/modal'],function(exports,_ember,_gooruWebUtilsUtils,_gooruWebConfigEnvironment,_gooruWebConfigConfig,_gooruWebMixinsModal){var ConfigEvent=_gooruWebConfigEnvironment['default'].events || {};exports['default'] = _ember['default'].Controller.extend(_gooruWebMixinsModal['default'],{ // -------------------------------------------------------------------------
// Dependencies
queryParams:['isLTIResourceLinkLaunch','isShare','isDeepLink'],isLTIResourceLinkLaunch:false,isShare:false, /**
   * @requires service:api-sdk/resource
   */resourceService:_ember['default'].inject.service('api-sdk/resource'),profileService:_ember['default'].inject.service('api-sdk/profile'),session:_ember['default'].inject.service('session'),eventsService:_ember['default'].inject.service('api-sdk/events'),tenantService:_ember['default'].inject.service('api-sdk/tenant'),usageReportService:_ember['default'].inject.service('usage-report'),currentProtocol:window.location.protocol,isStudentProfile:false,FEEDBACK:{LIKE:'like',DISLIKE:'dislike',UNLIKE:'unlike'},isShowFeedback:false, /**
   * The protocol for the resource url
   * @property {String}
   */resourceProtocol:_ember['default'].computed('resource.url',function(){var httpsPattern=/^(https:\/\/)/;var cdnPattern=/^(\/\/cdn.gooru.org\/)/;var httpsResult=httpsPattern.test(this.get('resource.body'));var cdnResult=cdnPattern.test(this.get('resource.body'));var resultProtocol=httpsResult === true || cdnResult === true?'https:':'http:';return resultProtocol;}), // -------------------------------------------------------------------------
// Properties
/**
   * @property {String} timeZone
   */timeZone:_ember['default'].computed(function(){return moment.tz.guess() || null;}),isShowHeaderButtons:_ember['default'].computed('isShowHeaderButtons',function(){return this.isLTIResourceLinkLaunch !== true;}),eventId:(0,_gooruWebUtilsUtils.generateUUID)(),startTime:new Date().getTime(),endTime:new Date().getTime(),sessionId:(0,_gooruWebUtilsUtils.generateUUID)(), /**
   * Indicates if the edit functionality is enabled
   * @property {boolean}
   */editEnabled:sessionStorage.getItem('content-source') === 'my-content',isDeepLink:false,usmCodesandDesc:_ember['default'].computed(function(){return null;}),isInformationTabActive:false, // -------------------------------------------------------------------------
// Actions
actions:{onExit:function onExit(){var controller=this;var eventContext=controller.getEventParams('stop');eventContext[0].endTime = new Date().getTime();var resource=controller.get('resource');controller.get('eventsService').saveResourceEvent(eventContext).then(function(){if(resource.isShare){window.history.back();}else {window.parent.postMessage('triggerclosebuttonevent','*');}})['catch'](function(){window.history.back();});},activateTab:function activateTab(){this.set('isInformationTabActive',true);},inActivateTab:function inActivateTab(){this.set('isInformationTabActive',false);},onEdit:function onEdit(){var component=this;var queryParams={queryParams:{editing:true}};var resourceID=this.get('resource.id');this.get('tenantService').removeStoredTenantSetting();this.get('tenantService').getActiveTenantSetting().then(function(res){var usmStandardsEnabled=false;usmStandardsEnabled = JSON.parse(res.enable_usm_standards);sessionStorage.setItem('editing_new_resource','true');var route=usmStandardsEnabled?'content.resource.editv2':'content.resources.edit';component.transitionToRoute(route,resourceID,queryParams);});},onAddToCollection:function onAddToCollection(){var component=this;var content=component.get('resource');component.get('profileService').readCollections(component.get('session.userId')).then(function(collections){var model={content:{resources:[content],format:content.type || content.contentFormat || content.format},collections:collections,assessments:[]};component.send('showModal','content.modals.gru-add-to-collection',model,null,'add-to');});}, //Action triggered when the user click on the thumbs up or thumbs down
//TODO: Call the API to save the user feedback
toggleThumbs:function toggleThumbs(action){var component=this;var resource=this.get('resource');var user=this.get('session');if(action === 'up'){this.set('feedback',this.feedback === this.FEEDBACK.LIKE?this.FEEDBACK.UNLIKE:this.FEEDBACK.LIKE);}else if(action === 'down'){this.set('feedback',this.feedback === this.FEEDBACK.DISLIKE?this.FEEDBACK.UNLIKE:this.FEEDBACK.DISLIKE);}var eventData={eventType:'event.content.' + this.feedback,id:resource.id,catalogId:resource.product_id.toString(),userId:user.get('userId'),role:(0,_gooruWebUtilsUtils.capitalizeFirstLetter)(user.get('role')),tenant:user.get('tenantId'),productCode:'CN',type:resource.type};component.get('usageReportService').saveEvent(eventData);}}, // -------------------------------------------------------------------------
// Methods
initialize:function initialize(){var _this=this;var controller=this;var resource=controller.get('resource');var currntUser=controller.get('session');this.set('isStudentProfile',currntUser.get('role') === 'student');var district_uid=window.localStorage.getItem('district_uid');_ember['default'].set(this,'isShowFeedback',!!resource.product_id && !!district_uid);if(resource.usmStandards != null){this.fetchUsmStandardsCodeAndDesc().then(function(usmCodesandDesc){_this.set('usmCodesandDesc',usmCodesandDesc);var eventContext=controller.getEventParams('start');controller.get('eventsService').saveResourceEvent(eventContext).then();});}else {var eventContext=controller.getEventParams('start');controller.get('eventsService').saveResourceEvent(eventContext).then();}this.get('isNotIframeUrl');this.get('isLinkOut');},fetchUsmStandardsCodeAndDesc:function fetchUsmStandardsCodeAndDesc(){return this.setusmStandardCodes().then(function(usmStandardsApiResponse){var mappedData=[];if(usmStandardsApiResponse){mappedData = JSON.parse(usmStandardsApiResponse).map(function(item){return {id:item.usm_code,title:item.usm_description,description:item.usm_description,frameworkCode:item.framework_id,parentTitle:null,code:item.usm_code};});}return mappedData;});},setusmStandardCodes:function setusmStandardCodes(){var resource=this.get('resource'); // Remove curly braces and split by comma to get an array
var usmUidsArray=resource.usmStandards.slice(1,-1).split(','); // Add double quotes around each string and create the parameter string
var quotedUsmUids=usmUidsArray.map(function(uid){return uid.trim();});var params=JSON.stringify({ids:quotedUsmUids});return this.get('resourceService').getUSMStandardsInfo(params).then(function(responseData){return responseData;});},isLinkOut:_ember['default'].computed('resource',function(){var currentProtocol=this.get('currentProtocol');var resourceProtocol=this.get('resourceProtocol');if(currentProtocol === 'https:' && resourceProtocol === 'http:' && this.get('resource').type !== 'html_resource'){return true;}return false;}), /**
   * Indicates if the current resource type is resource
   * @property {boolean}
   */isNotIframeUrl:_ember['default'].computed('resource',function(){var resource=this.get('resource');return resource && resource.displayGuide;}),getEventParams:function getEventParams(eventType){var controller=this;var eventId=controller.get('eventId');var apiKey=ConfigEvent.eventAPIKey;var userId=controller.get('session.userId');var resource=controller.get('resource');var partnerId=controller.get('session.partnerId');var sourceUrl=location.host;var eventData=[{eventId:eventId,eventName:'resource.play',context:{type:eventType,contentGooruId:resource.get('id'),resourceType:'resource',tenantId:controller.get('session.tenantId'),source:sourceUrl,partnerId:partnerId,contentSource:_gooruWebConfigConfig.PLAYER_EVENT_SOURCE.INDEPENDENT_ACTIVITY},payLoadObject:{gradingType:'system',isStudent:true},session:{apiKey:apiKey,sessionId:controller.get('sessionId')},user:{gooruUId:userId},version:{logApi:ConfigEvent.apiVersion},metrics:{},timezone:controller.get('timeZone'),startTime:controller.get('startTime'),endTime:controller.get('endTime')}];return eventData;}});});