define('gooru-web/services/configuration',['exports','ember','gooru-web/adapters/configuration','gooru-web/config/environment','gooru-web/config/env/development','gooru-web/config/env/test','gooru-web/config/env/production','gooru-web/config/env/features','gooru-web/config/config'],function(exports,_ember,_gooruWebAdaptersConfiguration,_gooruWebConfigEnvironment,_gooruWebConfigEnvDevelopment,_gooruWebConfigEnvTest,_gooruWebConfigEnvProduction,_gooruWebConfigEnvFeatures,_gooruWebConfigConfig){var ConfigurationService=_ember['default'].Service.extend({configurationAdapter:null, /**
   * Application configuration
   */configuration:null, /**
   * Feature flags
   */features:_ember['default'].computed.alias('configuration.features'),init:function init(){this._super.apply(this,arguments);this.set('configurationAdapter',_gooruWebAdaptersConfiguration['default'].create(_ember['default'].getOwner(this).ownerInjection()));},loadConfiguration:function loadConfiguration(){var configBaseUrl=arguments.length <= 0 || arguments[0] === undefined?null:arguments[0];var service=this;var environment=_gooruWebConfigEnvironment['default'].environment;var isProduction=environment === 'production';var isDevelopment=environment === 'development';var envConfiguration=isProduction?_gooruWebConfigEnvProduction['default']:isDevelopment?_gooruWebConfigEnvDevelopment['default']:_gooruWebConfigEnvTest['default'];var configuration=_ember['default'].Object.create(envConfiguration);configuration.setProperties(_gooruWebConfigEnvFeatures['default']); //setting default features behavior
//setting the configuration to the global variable
ConfigurationService.configuration = configuration;this.set('configuration',configuration);var hostname=window.location.hostname;return service.get('configurationAdapter').loadConfiguration(hostname,configBaseUrl).then(function(hostnameConfiguration){if(hostnameConfiguration){service.merge(hostnameConfiguration);}localStorage.setItem(_gooruWebConfigConfig.USAGE_REPORT_URL_KEY,hostnameConfiguration.usagereport.url);service.get('configurationAdapter').reservedWords(configBaseUrl).then(function(reservedWords){if(reservedWords){service.merge(reservedWords);}});return configuration;});}, /**
   * Merges properties
   */merge:function merge(props){this.get('configuration').setProperties(props);}});ConfigurationService.reopenClass({ /**
   * Application configuration properties
   */configuration:null});exports['default'] = ConfigurationService;});