define('gooru-web/services/usage-report',['exports','ember','gooru-web/adapters/usage-report','gooru-web/config/config'],function(exports,_ember,_gooruWebAdaptersUsageReport,_gooruWebConfigConfig){exports['default'] = _ember['default'].Service.extend({pbSessionTokenService:_ember['default'].inject.service('pb-session-token'),usageReportAdapter:null,interval:null, // track ongoing token fetch
_tokenFetchPromise:null, /**
   * Initializes the service and sets the usage report adapter.
   */init:function init(){this._super.apply(this,arguments);this.set('usageReportAdapter',_gooruWebAdaptersUsageReport['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Checks if the current session is valid.
   *
   * @returns {boolean} True if the deep link is active and the district id is present, false otherwise.
   * @memberof UsageReportService
   */isValidSession:function isValidSession(){return (localStorage.getItem('isDeepLink') === 'true' || localStorage.getItem('isLTIResourceLinkLaunch') === 'true') && Boolean(localStorage.getItem('district_uid'));}, /**
   * Saves an event by delegating to the usage report adapter.
   *
   * @param {Object} eventData - The data of the event to be saved.
   * @returns {Promise} The promise object representing the result of the save operation.
   */saveEvent:function saveEvent(eventData){if(!this.isValidSession()){return new _ember['default'].RSVP.Promise(function(_,reject){reject('Invalid session');});}var service=this;return service.getTokenIfNotAvailable().then(function(){return service.get('usageReportAdapter').saveEvent(eventData);});},getEventByContentId:function getEventByContentId(eventData){if(!this.isValidSession()){return new _ember['default'].RSVP.Promise(function(_,reject){reject('Invalid session');});}var service=this;return service.getTokenIfNotAvailable().then(function(){return service.get('usageReportAdapter').getEventByContentId(eventData);});},getTokenIfNotAvailable:function getTokenIfNotAvailable(){if(localStorage.getItem(_gooruWebConfigConfig.USAGE_REPORT_TOKEN_KEY)){return new _ember['default'].RSVP.Promise(function(resolve){resolve();});}var service=this;if(service._tokenFetchPromise){return service._tokenFetchPromise;}service.fetchAndSaveToken();return service._tokenFetchPromise;}, /**
   * Fetches a new token from the pbSessionTokenService and saves it to sessionStorage.
   *
   * @returns {Promise} The promise object representing the result of the token fetch operation.
   */fetchAndSaveToken:function fetchAndSaveToken(){var service=this;var isDeepLink=localStorage.getItem('isDeepLink') === 'true';var isLTIResourceLinkLaunch=localStorage.getItem('isLTIResourceLinkLaunch') === 'true';var districtId=localStorage.getItem('district_uid');var role=service.get('pbSessionTokenService').getRole(_gooruWebConfigConfig.CN_USAGE_REPORT_SCOPE);if((isDeepLink || isLTIResourceLinkLaunch) && districtId && role){service._tokenFetchPromise = service.get('pbSessionTokenService').getToken(role).then(function(token){localStorage.setItem(_gooruWebConfigConfig.USAGE_REPORT_TOKEN_KEY,token);localStorage.setItem(_gooruWebConfigConfig.USAGE_REPORT_TOKEN_LASTUPDATED_KEY,Date.now());return token;});return service._tokenFetchPromise;}else {return new _ember['default'].RSVP.Promise(function(_,reject){reject('Invalid session');});}}, /**
   * Updates the token by fetching a new one and setting an interval to refresh it periodically.
   */updateToken:function updateToken(){var service=this;if(!service.isValidSession()){return new _ember['default'].RSVP.Promise(function(_,reject){reject('Invalid session');});}var lastUpdatedString=localStorage.getItem(_gooruWebConfigConfig.USAGE_REPORT_TOKEN_LASTUPDATED_KEY);var lastUpdated=lastUpdatedString?new Date(parseInt(lastUpdatedString)):null;if(!lastUpdated ||  // to handle if the token was never set
Date.now() - lastUpdated.getTime() > _gooruWebConfigConfig.USAGE_REPORT_TOKEN_EXPIRATION // to handle if the token expired
){return service.fetchAndSaveToken().then(function(){if(service.get('interval')){clearInterval(service.get('interval'));}service.set('interval',setInterval(service.fetchAndSaveToken.bind(service),_gooruWebConfigConfig.USAGE_REPORT_TOKEN_EXPIRATION));});}return new _ember['default'].RSVP.Promise(function(resolve){resolve();});}});});