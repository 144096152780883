define('gooru-web/components/content/modals/gru-add-to',['exports','ember','gooru-web/utils/utils'],function(exports,_ember,_gooruWebUtilsUtils){exports['default'] = _ember['default'].Component.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @property {Service} I18N service
   */i18n:_ember['default'].inject.service(), /**
   * @property {Service} Notifications service
   */notifications:_ember['default'].inject.service(), /**
   * @property {Service} Session service
   */session:_ember['default'].inject.service(), /**
   * @property {Service} UsageReport service
   */usageReportService:_ember['default'].inject.service('usage-report'), // -------------------------------------------------------------------------
// Attributes
classNames:['content','modals','gru-add-to'],classNameBindings:['component-class'],district_uid:window.localStorage.getItem('district_uid'), // -------------------------------------------------------------------------
// Actions
actions:{ /**
     * Action triggered when a collection/assessment is selected
     */selectCollection:function selectCollection(collection){this.set('selectedCollection',collection);$('.gru-add-to .selected').removeClass('selected');$('.' + collection.id).addClass('selected');}, /**
     * Action triggered when clicking add to
     */addTo:function addTo(){var _this=this;this.$('.modal-footer button.add-to').prop('disabled',true);this.get('copyContent').call(this).then(this.get('addContent').bind(this)).then(this.get('successMessage').bind(this)).then(function(){var content=_this.get('content');if(_this.district_uid && content.contentFormat === 'resource' && content.resources.length > 0){(function(){var user=_this.get('session');content.resources.filter(function(resource){return resource.product_id || resource.productInfo && resource.productInfo.id;}).forEach(function(resource){var eventData={eventType:'event.content.addtoplaylist',id:resource.id,catalogId:resource.product_id?resource.product_id.toString():resource.productInfo.id.toString(),userId:user.get('userId'),role:(0,_gooruWebUtilsUtils.capitalizeFirstLetter)(user.get('role')),tenant:user.get('tenantId'),productCode:'CN',type:resource.contentSubFormat || resource.type};_this.get('usageReportService').saveEvent(eventData);});})();}_this.get('content.resources').clear();_this.triggerAction({action:'closeModal'});})['catch'](this.get('errorMessage').bind(this));}, /**
     * Action triggered to redirect to the collection/assessment player
     */openCollectionPlayer:function openCollectionPlayer(collectionId){this.get('router').transitionTo('player',collectionId);this.triggerAction({action:'closeModal'});}}, // -------------------------------------------------------------------------
// Events
init:function init(){this._super.apply(this,arguments);this.set('collections',this.get('model.collections'));this.set('content',this.get('model.content'));}, // -------------------------------------------------------------------------
// Properties
/**
   * @type {?String} specific class
   */'component-class':null, /**
   * @type {String} selected collection/assessment ID
   */selectedCollection:null, /**
   * @type {String} selected collection/assessment ID
   */hasSelectedCollection:_ember['default'].computed.notEmpty('selectedCollection'), /**
   * @type {List} user collections
   */collections:null, /**
   * @type {Resource/Question} resource, question or lesson needed to add
   */content:null, /**
   * @type {String} name of the collection type
   */collectionType:_ember['default'].computed('selectedCollection',function(){return this.get('selectedCollection.isCollection')?this.get('i18n').t('common.collection').string:this.get('i18n').t('common.assessment').string;})});});