define('gooru-web/routes/player-resource',['exports','ember'],function(exports,_ember){exports['default'] = _ember['default'].Route.extend({ // -------------------------------------------------------------------------
// Dependencies
/**
   * @requires service:api-sdk/resource
   */resourceService:_ember['default'].inject.service('api-sdk/resource'), /**
   * @type {ProfileService} Service to retrieve profile information
   */profileService:_ember['default'].inject.service('api-sdk/profile'), /**
   * @requires service:session
   */session:_ember['default'].inject.service('session'), //-------------------------------------------------------------------------
// Methods
beforeModel:function beforeModel(transition){var sessionJSON=window.localStorage.getItem('ember_simple_auth-session');var resourceShareLinkId=transition.params['player-resource'].resourceId;if(sessionJSON){var sessionObj=JSON.parse(sessionJSON);if(sessionObj && sessionObj.authenticated && sessionObj.authenticated.isAnonymous){localStorage.setItem('resourceShareLinkId',resourceShareLinkId);this.get('router').transitionTo('login');}}},model:function model(params){localStorage.setItem('isLTIResourceLinkLaunch',params.isLTIResourceLinkLaunch || "false");var route=this;var isDeepLink=JSON.parse(localStorage.getItem('isDeepLink') || 'false');var resource=this.get('resourceService').readResource(params.resourceId).then(function(resource){return route.get('profileService').readUserProfile(resource.owner).then(function(owner){resource.set('owner',owner);if(resource.get('format') === 'h5p'){resource.set('format',resource.get('type'));}resource.set('body',resource.get('url'));if(params.isShare){resource.set('isShare',JSON.parse(params.isShare));}return _ember['default'].RSVP.resolve(resource);});});return _ember['default'].RSVP.hash({resource:resource,isDeepLink:isDeepLink});},setupController:function setupController(controller,model){controller.set('resource',model.resource);controller.set('isDeepLink',model.isDeepLink);controller.initialize();}});});