define('gooru-web/services/pb-session-token',['exports','ember','gooru-web/config/config'],function(exports,_ember,_gooruWebConfigConfig){ /**
 * Service for the PB Session Token API.
 * This service is responsible for fetching and refreshing the PB Session Token.
 *
 * @typedef {Object} PB Session Token Service
 * @property {SessionService} session
 * @property {string} token
 * @property {number} expires
 * @property {string} tokenEndpoint
 * @method getToken
 */exports['default'] = _ember['default'].Service.extend({session:_ember['default'].inject.service('session'),token:null,expires:null,tokenEndpoint:'/api/v1/pb-session-token-manager/getToken',getToken:function getToken(scopes){var service=this;var isDeepLink=localStorage.getItem('isDeepLink') === 'true';var isDistrictIdPresent=Boolean(localStorage.getItem('district_uid'));var isLTIResourceLinkLaunch=window.localStorage.getItem('isLTIResourceLinkLaunch') === 'true';if(!isDeepLink && !isLTIResourceLinkLaunch || !isDistrictIdPresent){return _ember['default'].RSVP.reject('Invalid session');}return _ember['default'].$.ajax(service.get('tokenEndpoint'),{method:'POST',headers:service.getRequestHeaders(),contentType:'application/json',data:service.getRequestPayload(scopes)}).then(function(response){if(response.success){service.set('token',response.data.sessionId);service.set('expires',response.data.expires);_ember['default'].Logger.info('PB Session token refreshed');return service.get('token');}else {throw new Error('API request failed');}})['catch'](function(error){_ember['default'].Logger.error('API request failed:',error);throw error;});},getRequestHeaders:function getRequestHeaders(){var service=this;return {Authorization:'Token ' + service.get('session.token-api3')};},getRequestPayload:function getRequestPayload(scopes){return JSON.stringify({scopes:[scopes],productShortName:_gooruWebConfigConfig.SGY_PRODUCT_SHORT_NAME});},getRole:function getRole(scope){var service=this;var role=service.get('session.role');if(_gooruWebConfigConfig.CN_SCOPES.includes(scope)){if(_gooruWebConfigConfig.CN_1_TO_2_ROLE_MAPPING[role]){return scope + '.' + role;}return null;}return scope;}});});