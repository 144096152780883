define('gooru-web/services/usage-report',['exports','ember','gooru-web/adapters/usage-report','gooru-web/config/config'],function(exports,_ember,_gooruWebAdaptersUsageReport,_gooruWebConfigConfig){exports['default'] = _ember['default'].Service.extend({pbSessionTokenService:_ember['default'].inject.service('pb-session-token'),usageReportAdapter:null,interval:null, /**
   * Initializes the service and sets the usage report adapter.
   */init:function init(){this._super.apply(this,arguments);this.set('usageReportAdapter',_gooruWebAdaptersUsageReport['default'].create(_ember['default'].getOwner(this).ownerInjection()));}, /**
   * Saves an event by delegating to the usage report adapter.
   *
   * @param {Object} eventData - The data of the event to be saved.
   * @returns {Promise} The promise object representing the result of the save operation.
   */saveEvent:function saveEvent(eventData){var service=this;return new _ember['default'].RSVP.Promise(function(resolve,reject){service.get('usageReportAdapter').saveEvent(eventData).then(resolve,reject);});}, /**
   * Updates the token by fetching a new one and setting an interval to refresh it periodically.
   */updateToken:function updateToken(){var service=this; /**
     * Fetches a new token from the pbSessionTokenService and saves it to sessionStorage.
     *
     * @returns {Promise} The promise object representing the result of the token fetch operation.
     */function fetchAndSaveToken(){service.get('pbSessionTokenService').getToken(_gooruWebConfigConfig.USAGE_REPORT_SCOPES).then(function(token){sessionStorage.setItem(_gooruWebConfigConfig.USAGE_REPORT_TOKEN_KEY,token);});}fetchAndSaveToken();if(service.get('interval')){clearInterval(service.get('interval'));}service.set('interval',setInterval(fetchAndSaveToken,_gooruWebConfigConfig.USAGE_REPORT_TOKEN_EXPIRATION));}});});